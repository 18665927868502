import BN from "bn.js";

export const sortByBalance = (arr: any[]) => arr.sort((a, b) => (new BN(a.balance).lt(new BN(b.balance)) ? 1 : -1));

// @ts-ignore
export const sortBySymbol: <T>(arr: T[]) => T[] = (arr) => arr.sort((a, b) => (a.symbol > b.symbol ? 1 : -1));

const onlyUnique = <T>(value: T, index: number, self: T[]) => {
    return self.indexOf(value) === index;
};
export const getUnique = <T>(arr: T[]) => arr.filter(onlyUnique);
