import React, { useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import BN from "bn.js";
import MoralisType from "moralis/types";
import Web3 from "web3";

import Button from "../../ui-kit/components/Button/Button";
import ButtonGradient from "../../ui-kit/components/ButtonGradient/ButtonGradient";
import { sortBySymbol } from "../../utils/array";
import { beautifyTokenBalance, BalanceType, toHRNumberFloat } from "../../utils/tokens";
import { AllowanceDataType, getAllowances, getTokenContract, isUnlimited } from "../../utils/allowance";
import { getAddressLink, getTokenLink, idToNetwork, sleep } from "../../utils/network";

import "./Allowances.scss";
import { addErrorNotification } from "../../utils/notifications";

const Allowances = () => {
    const { account, chainId: hexChainId, web3 } = useMoralis();
    const Web3Api = useMoralisWeb3Api();
    const [isCheckLoading, setIsCheckLoading] = useState(false);
    // [token, spender] or undefined if no loading
    const [isRevokeLoading, setIsRevokeLoading] = useState<string[] | undefined>(undefined);
    const [allowances, setAllowances] = useState<AllowanceDataType[] | undefined>(undefined);
    const [status, setStatus] = useState<number[] | undefined>(undefined);
    const chainId = Web3.utils.hexToNumber(hexChainId ?? "");
    const networkName = idToNetwork[chainId];

    useEffect(() => {}, []);

    if (!account) {
        return null;
    }

    const handleCheckAllowance = async () => {
        setIsCheckLoading(true);
        setAllowances(undefined);
        try {
            const options = { chain: hexChainId, address: account };
            // @ts-ignore
            const result: BalanceType[] = await Web3Api.account.getTokenBalances(options);
            const sortedBalances = sortBySymbol(result);
            const newAllowances = [];
            for (let i = 0; i < sortedBalances.length; ++i) {
                setStatus((oldStatus) =>
                    oldStatus ? [oldStatus[0] + 1, sortedBalances.length] : [0, sortedBalances.length]
                );
                newAllowances.push(
                    await getAllowances({
                        balanceData: sortedBalances[i],
                        hexChainId,
                        account,
                        web3,
                        Web3Api: Web3Api as unknown as typeof MoralisType.Web3API,
                    })
                );
                await sleep(40);
            }

            setAllowances(newAllowances);
            setIsCheckLoading(false);
            setStatus(undefined);
        } catch (e) {
            setIsCheckLoading(false);
            setStatus(undefined);
        }
    };

    const removeAllowanceInternal = (token: string, spender: string) => {
        setAllowances((oldAllowances) => {
            if (oldAllowances?.length) {
                const allowancesDataIndex = oldAllowances?.findIndex((v) => v.token_address === token);
                const allowancesData = oldAllowances[allowancesDataIndex];
                const allowanceIndex = allowancesData?.allowances.findIndex((v) => v.spender === spender) ?? -1;

                if (allowancesData && allowanceIndex !== -1) {
                    const arr = allowancesData.allowances;
                    allowancesData.allowances = [...arr.slice(0, allowanceIndex), ...arr.slice(allowanceIndex + 1)];
                }

                return [
                    ...oldAllowances.slice(0, allowancesDataIndex),
                    allowancesData,
                    ...oldAllowances.slice(allowancesDataIndex + 1),
                ];
            }
        });
    };

    const handleRevoke = (token: string, spender: string) => async () => {
        const tokenContract = getTokenContract(web3, token);
        setIsRevokeLoading([token, spender]);

        try {
            const trx = await tokenContract.approve(spender, "0");
            await trx.wait();
            setIsRevokeLoading(undefined);
            removeAllowanceInternal(token, spender);
        } catch (error) {
            setIsRevokeLoading(undefined);
            // @ts-ignore
            const replacedHash = error?.replacement?.hash;
            if (replacedHash) {
                removeAllowanceInternal(token, spender);
            } else {
                console.error(error);
                addErrorNotification("Error", "Revoke transaction failed");
            }
        }
    };

    if (allowances?.length === 0) {
        return <div className="allowances">No allowances</div>;
    }

    return (
        <div className="allowances">
            {allowances ? (
                <>
                    {allowances.map((allowance) => (
                        <div className="allowances-data" key={allowance.token_address}>
                            <div className="allowances-data__title">
                                <a
                                    href={getTokenLink(allowance.token_address, networkName)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>{allowance.symbol}: </span>
                                    <span>{toHRNumberFloat(new BN(allowance.balance), +allowance.decimals)}</span>
                                </a>
                            </div>
                            {allowance.allowances.length > 0 ? (
                                <div className="allowances-data__allowances">
                                    {allowance.allowances.map(({ amount, spender }) => {
                                        const isLoading =
                                            isRevokeLoading &&
                                            isRevokeLoading[0] === allowance.token_address &&
                                            isRevokeLoading[1] === spender;
                                        return (
                                            <div className="allowances-data__allowances__item" key={spender}>
                                                {isUnlimited(amount)
                                                    ? "Unlimited"
                                                    : beautifyTokenBalance(amount, +allowance.decimals)}{" "}
                                                allowance to&nbsp;
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={getAddressLink(spender, networkName)}
                                                >
                                                    {spender}
                                                </a>
                                                <Button
                                                    padding="4px 8px"
                                                    className="allowances-data__allowances__revoke-button"
                                                    onClick={handleRevoke(allowance.token_address, spender)}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? "Loading..." : "Revoke"}
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="allowances-data__allowances__item">No allowances</div>
                            )}
                        </div>
                    ))}
                </>
            ) : (
                <>
                    <ButtonGradient width={200} onClick={handleCheckAllowance} disabled={isCheckLoading}>
                        {isCheckLoading ? "Loading..." : "Check allowances"}
                    </ButtonGradient>
                    {status && (
                        <div className="allowances__status">
                            Status: {status[0]} of {status[1]}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Allowances;
