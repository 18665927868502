import React, { useEffect } from "react";
import { useMoralis } from "react-moralis";

import Header from "../Header/Header";
import { Footer } from "../Footer/Footer";
import Allowances from "../Allowances/Allowances";
import "./App.scss";

const DEFAULT_CHAIN_ID = 137;

const App = () => {
    const { logout, authenticate, isWeb3Enabled, isAuthenticated, enableWeb3 } = useMoralis();

    const onDisconnect = async () => {
        await logout();
    };

    const onWalletConnect = async () => {
        authenticate({
            provider: "walletconnect",
        });
    };

    const onMetamaskConnect = () => {
        enableWeb3({ chainId: DEFAULT_CHAIN_ID });
    };

    useEffect(() => {
        if (!isWeb3Enabled && isAuthenticated) {
            enableWeb3({ chainId: DEFAULT_CHAIN_ID });
        }
    }, [isWeb3Enabled, isAuthenticated, enableWeb3]);

    return (
        <div className="App">
            <Header
                onMetamaskConnect={onMetamaskConnect}
                onWalletConnect={onWalletConnect}
                onDisconnect={onDisconnect}
            />
            <div className="App__container">
                <Allowances />
            </div>
            <Footer />
        </div>
    );
};

export default App;
