import React from "react";
import { useMoralis } from "react-moralis";

import { getShortAddress } from "../../utils/wallet";

import "./Header.scss";

interface NavbarProps {
    onMetamaskConnect: () => void;
    onWalletConnect: () => void;
    onDisconnect: () => void;
}

const Header = ({ onWalletConnect, onMetamaskConnect, onDisconnect }: NavbarProps) => {
    const { account } = useMoralis();

    return (
        <div className="header">
            <div className="header__logo">
                <a href="https://stableunit.org/" target="_blank" rel="noreferrer">
                    <img src="https://stableunit.org/assets/img/logo.svg" />
                </a>
            </div>
            <div className="header__navbar">
                {account ? (
                    <div className="header__address" onClick={onDisconnect}>
                        {getShortAddress(account)}
                    </div>
                ) : (
                    <div>
                        <div className="header__button" onClick={onMetamaskConnect} id="connect-button-metamask">
                            Connect wallet
                        </div>
                        <div className="header__button" onClick={onWalletConnect} id="connect-button-wallet-connect">
                            Connect wallet
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
