import MoralisType from "moralis/types";
import Moralis from "moralis";
import { Contract } from "ethers";

import Web3 from "web3";
import { APPROVAL_TOPIC, getAccountFromTopic, getAccountTopic } from "./events";
import { getUnique } from "./array";
import { BalanceType } from "./tokens";
import CONTRACT_ERC20 from "../contracts/ERC20.json";
import { idToNetwork, NETWORK } from "./network";

export const getTokenContract = (web3: Moralis.MoralisWeb3Provider | null, address: string) => {
    const ethers = Moralis.web3Library;
    // @ts-ignore
    return new ethers.Contract(address, CONTRACT_ERC20, web3?.getSigner());
};

interface GetAllowancesBySpenderArgs {
    spender: string;
    hexChainId: string | null;
    account: string;
    Web3Api: typeof MoralisType.Web3API;
    tokenContract: Contract;
}
const getAllowanceBySpender = async ({ spender, account, tokenContract }: GetAllowancesBySpenderArgs) => {
    const allowance = await tokenContract.functions.allowance(account, spender);

    return { spender, amount: allowance.toString() };
};

export type AllowanceDataType = BalanceType & {
    allowances: {
        amount: string;
        spender: string;
    }[];
};
interface GetAllowancesArgs {
    balanceData: BalanceType;
    hexChainId: string | null;
    account: string;
    web3: Moralis.MoralisWeb3Provider | null;
    Web3Api: typeof MoralisType.Web3API;
}
export const getAllowances = async ({ balanceData, hexChainId, account, Web3Api, web3 }: GetAllowancesArgs) => {
    const chainId = Web3.utils.hexToNumber(hexChainId ?? "");
    const networkName = idToNetwork[chainId];
    const tokenContract = getTokenContract(web3, balanceData.token_address);
    const filter = {
        address: balanceData.token_address,
        topics: [APPROVAL_TOPIC, getAccountTopic(account)],
    };
    let uniqueSpenders: string[];
    // BNB https://github.com/bnb-chain/bsc/issues/113
    if (networkName === NETWORK.bsc || networkName === NETWORK.polygon) {
        const logs = await Web3Api.native.getLogsByAddress({
            address: balanceData.token_address,
            // @ts-ignore
            chain: hexChainId,
            topic0: APPROVAL_TOPIC,
            topic1: getAccountTopic(account),
            from_block: "0",
        });
        // @ts-ignore
        uniqueSpenders = getUnique(logs.result.map((v) => getAccountFromTopic(v.topic2)));
    } else {
        const logs = await tokenContract.queryFilter(filter);
        uniqueSpenders = getUnique(logs.map((v) => getAccountFromTopic(v.topics[2])));
    }

    const allowances = [];
    for (let i = 0; i < uniqueSpenders.length; ++i) {
        allowances.push(
            await getAllowanceBySpender({
                spender: uniqueSpenders[i],
                hexChainId,
                account,
                Web3Api,
                tokenContract,
            })
        );
    }

    return {
        ...balanceData,
        allowances: allowances.filter((v) => v.amount !== "0"),
    } as AllowanceDataType;
};

interface RemoveAllowanceProps {
    from: string;
    to: string;
    token: string;
    web3: Moralis.MoralisWeb3Provider | null;
}
export const removeAllowance = async ({ web3, token, from, to }: RemoveAllowanceProps) => {
    const tokenContract = getTokenContract(web3, token);
    const trx = await tokenContract.approve(to, "0");
    await trx.wait();
};

export const isUnlimited = (amount: string) => amount.length === 78;
