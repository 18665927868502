import React, { useReducer } from "react";
import ReactDOM from "react-dom";
import { MoralisProvider } from "react-moralis";
import { ReactNotifications } from "react-notifications-component";

import App from "./components/App/App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

import "./index.scss";
import "react-notifications-component/dist/theme.css";

const serverUrl = "https://7j99nsfhpjl8.usemoralis.com:2053/server";
const appId = "s84PbpmgTx7kZ4go5mpLAqZPSbPmxaiNTIfm3YA2";

const AppContainer = () => {
    return (
        <MoralisProvider appId={appId} serverUrl={serverUrl}>
            <ErrorBoundary>
                <ReactNotifications />
                <App />
            </ErrorBoundary>
        </MoralisProvider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <AppContainer />
    </React.StrictMode>,
    document.getElementById("root")
);
